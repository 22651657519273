import { useIntervalFn } from '@vueuse/core';

const competitionPrizePool = ref(600000);
const closeTime = ref(null);
const interval = ref(null);
const days = ref('00');
const hours = ref('00');
const minutes = ref('00');
const seconds = ref('00');

export function useDynamicMillionaire(compId) {
  const sweepstakeStore = useSweepstakeStore();
  const { getSweepstakeById, } = sweepstakeStore;

  function init() {
    if (interval.value || !compId) {
      return;
    }

    interval.value = useIntervalFn(async() => {
      const response = await getSweepstakeById(compId);
      competitionPrizePool.value = response.totalFunds;
      closeTime.value = response.closeTime;
    }, 10000, {
      immediateCallback: true,
    });
  }

  function updateCountdownTimer() {
    const { $dayjs, } = useNuxtApp();

    const now = $dayjs();
    const endDate = $dayjs(closeTime.value);
    const secondsLocal = endDate.diff(now, 's');

    if (secondsLocal > 0) {
      days.value = String(Math.floor(secondsLocal / (3600 * 24))).padStart(2, '0');
      hours.value = String(Math.floor(secondsLocal % (3600 * 24) / 3600)).padStart(2, '0');
      minutes.value = String(Math.floor(secondsLocal % 3600 / 60)).padStart(2, '0');
      seconds.value = String(Math.floor(secondsLocal % 60)).padStart(2, '0');
    } else {
      days.value = '00';
      hours.value = '00';
      minutes.value = '00';
      seconds.value = '00';
    }
  }

  init();

  onBeforeUnmount(() => {
    if (interval.value && !!interval.value?.stop) {
      interval.value.stop();
      interval.value = null;
    }
  });

  return {
    competitionPrizePool,
    init,
    updateCountdownTimer,
    days,
    hours,
    minutes,
    seconds,
  };
}
